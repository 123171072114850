import React from 'react';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';
import PageTemplate from 'modules/layout/PageTemplate';
import SectionCentered from 'modules/layout/SectionCentered';
import PageHeading from 'components/modules/Shared/PageHeading';
import FingerprintsWithChallengeContainer from 'redux/modules/FingerprintsWithChallenge/container';

const PaymentPage = (props) => {
  const analytics = useTracking();
  return (
    <PageTemplate
      helmetTitle="Payment Verification"
      headerProps={props}
      footerProps={props}
    >
      <PageHeading title="Payment Verification" />

      <SectionCentered style={{ marginTop: 30, marginBottom: 30 }}>
        <FingerprintsWithChallengeContainer
          handleError={() =>
            analytics.track(EVENTS.PAYMENT_ERRORED, { reason: 'gateway error' })
          }
          handleRejected={() =>
            analytics.track(EVENTS.PAYMENT_REJECTED, {
              reason: 'gateway rejection',
            })
          }
          handleSuccess={() => analytics.track(EVENTS.PAYMENT_COMPLETED)}
        />
      </SectionCentered>
    </PageTemplate>
  );
};

export default PaymentPage;
