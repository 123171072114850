import React from 'react';
import Radium from 'radium';

class LoadingVerification extends React.Component {
  render() {
    return <div>Loading...</div>;
  }
}

export default Radium(LoadingVerification);
