import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import PageHeading from 'components/modules/Shared/PageHeading';
import PageTemplate from 'modules/layout/PageTemplate';
import LoadingIndicator from 'modules/Shared/LoadingIndicator';

class ProxyLogin extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const query = new URLSearchParams(location.hash.replace(/^#/, ''));
    this.token = query.get('token');
    this.partnerId = query.get('partnerId');
    this.expiresIn = query.get('expiresIn');
    this.tokenType = query.get('tokenType');
  }

  componentDidMount() {
    const { proxyLogin } = this.props;
    proxyLogin(this.token, this.partnerId, this.expiresIn, this.tokenType);
  }

  render() {
    return (
      <PageTemplate helmetTitle="Signing you in">
        <PageHeading title="Signing you in" />
        <LoadingIndicator loading={true} />
      </PageTemplate>
    );
  }
}

ProxyLogin.defaultProps = {};

ProxyLogin.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
  proxyLogin: PropTypes.func.isRequired,
};

export default withRouter(ProxyLogin);
