// pages/Content/index.js
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from 'redux/modules';

import ContentPageUI from './page';

const content = (state) => {
  return state.get('Content').toJS();
};

const ContentPage = connect(
  (state) => {
    return content(state);
  },
  (dispatch) =>
    bindActionCreators(
      {
        ...actionCreators.Content,
      },
      dispatch
    )
)(ContentPageUI);

export default ContentPage;
