import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const style = (theme) => ({
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: `${theme.spacing(2)}px`,
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      columnGap: `${theme.spacing(3)}px`,
      flexDirection: 'row',
    },
  },
  progress: {
    flexShrink: 0,
  },
  // TODO: update alert styles for outlined variant
  message: {
    padding: theme.spacing(2, 2, 2, 1),
    color: theme.palette.text.primary,
    backgroundColor: fade(theme.palette.warning.main, 0.1),
    borderWidth: 2,
  },
  messageIcon: {
    color: 'currentColor !important',
  },
  messageContent: {
    maxWidth: '72ch',
    padding: 0,
  },
});

export default makeStyles(style);
