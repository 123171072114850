import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import LoadingPage from 'components/pages/Loading';

const SignIn = ({ location, salesAuthRequest }) => {
  useLayoutEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    salesAuthRequest(token);
  }, []);

  return <LoadingPage title="Signing you in" />;
};

SignIn.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  salesAuthRequest: PropTypes.func.isRequired,
};

export default withRouter(SignIn);
