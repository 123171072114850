import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import PageHeading from 'components/modules/Shared/PageHeading';
import PageTemplate from 'modules/layout/PageTemplate';
import LoadingIndicator from 'modules/Shared/LoadingIndicator';
import ErrorMessage from 'modules/Shared/ErrorMessage';
import { REQUEST_SENDING } from 'constants/requestStatus';
import { POST_SSO_TOKEN_REQ } from 'redux/modules/PartnerLogin/reducer';

class SignIn extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const query = new URLSearchParams(location.search);
    this.token = query.get('token');
    this.partnerId = query.get('partnerId');
  }

  componentDidMount() {
    const { ssoVerifyRequest } = this.props;
    ssoVerifyRequest(this.token, this.partnerId);
  }

  render() {
    const { error, requestStatus } = this.props;

    return (
      <PageTemplate helmetTitle="Signing you in">
        <PageHeading title="Signing you in" />
        {!!error.message && <ErrorMessage message={error.message} />}
        <LoadingIndicator
          loading={requestStatus[POST_SSO_TOKEN_REQ] === REQUEST_SENDING}
        />
      </PageTemplate>
    );
  }
}

SignIn.defaultProps = {
  error: null,
};

SignIn.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  ssoVerifyRequest: PropTypes.func.isRequired,
};

export default withRouter(SignIn);
