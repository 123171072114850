import React from 'react';
import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'components/modules/Shared/PageHeading';
import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import { BroadbandContainer } from 'redux/modules/Broadband/container';

const BroadbandPage = () => (
  <PageTemplate helmetTitle="Your broadband">
    <PageHeading title="Your broadband" />
    <ApplicationCheck>
      <BroadbandContainer />
    </ApplicationCheck>
  </PageTemplate>
);

export default BroadbandPage;
