import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import illustrations from '@utilitywarehouse/partner-ui-assets/lib/js/images/illustrations';

import PageHeading from 'modules/Shared/PageHeading';
import PageTemplate from 'modules/layout/PageTemplate';
import SectionCentered from 'modules/layout/SectionCentered';
import SupportingForm from 'redux/modules/RemoteSession/v2/SupportingForm/container';

import styles from './styles';

const RemoteSupport = ({ classes }) => (
  <PageTemplate helmetTitle="Join Remote Appointment">
    <PageHeading autoScroll={false}>
      <div className={classes.container}>
        <Typography className={classes.title} variant="h1">
          Remote supporting
        </Typography>
        <img
          className={classes.image}
          alt="woman image"
          src={illustrations.i6.outlined}
        />
      </div>
    </PageHeading>
    <SectionCentered>
      <SupportingForm />
    </SectionCentered>
  </PageTemplate>
);

RemoteSupport.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RemoteSupport);
