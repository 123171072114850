import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { withRouter } from 'react-router-dom';

import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'components/modules/Shared/PageHeading';
import SectionCentered from 'modules/layout/SectionCentered';
import styles from './styles';

class NotFoundPage extends React.Component {
  render() {
    return (
      <PageTemplate
        helmetTitle="Utility Warehouse - Join the club - Page not found"
        headerProps={this.props}
        footerProps={this.props}
      >
        <PageHeading title="Sorry!" />
        <SectionCentered styleContainer={styles.content}>
          <h1 style={styles.mainHeading}>
            There appears to be a problem. Please try again later.
          </h1>
          <h4 style={styles.secondaryHeading}>
            We would hate for you to miss out on the great value services and
            products that we provide, so please visit our main page and try
            finding what you are looking for from there.
          </h4>
          <h4 onClick={() => this.props.history.push('/')} style={styles.link}>
            Go to our home page
          </h4>
        </SectionCentered>
      </PageTemplate>
    );
  }
}
NotFoundPage.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  history: PropTypes.object.isRequired,
};

export default withRouter(Radium(NotFoundPage));
