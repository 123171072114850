import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import illustrations from '@utilitywarehouse/partner-ui-assets/lib/js/images/illustrations';

import PageHeading from 'modules/Shared/PageHeading';
import PageTemplate from 'modules/layout/PageTemplate';
import PartnerForm from 'redux/modules/RemoteSession/v2/PartnerForm/container';
import * as consts from './consts';
import useStyles from './styles';

const RemotePage = ({ isPartnerLoggedIn, isRemoteSession }) => {
  const classes = useStyles();

  return (
    <PageTemplate helmetTitle="Start Remote Appointment">
      <PageHeading autoScroll={false}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            {!isPartnerLoggedIn && (
              <Fragment>
                <Typography className={classes.title} variant="h1">
                  {consts.TITLE}
                </Typography>
                <Typography className={classes.subtitle} variant="h3">
                  {consts.SUBTITLE}
                </Typography>
              </Fragment>
            )}
            {isPartnerLoggedIn && !isRemoteSession && (
              <Typography className={classes.title} variant="h1">
                {consts.TITLE_LOGGEDIN}
              </Typography>
            )}
            {isPartnerLoggedIn && isRemoteSession && (
              <Typography className={classes.title} variant="h1">
                {consts.REMOTE_SESSION}
              </Typography>
            )}
          </Box>
          {!(isPartnerLoggedIn && isRemoteSession) && (
            <img
              className={classes.image}
              alt="woman image"
              src={illustrations.i6.outlined}
            />
          )}
        </Box>
      </PageHeading>
      <PartnerForm />
    </PageTemplate>
  );
};

RemotePage.propTypes = {
  isPartnerLoggedIn: PropTypes.bool.isRequired,
  isRemoteSession: PropTypes.bool.isRequired,
};

export default RemotePage;
