import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'components/modules/Shared/PageHeading';
import EnergyProductSelectionFormContainer from 'redux/modules/Energy/ProductSelectionForm/container';
import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';

class EnergySelectionPage extends React.Component {
  static defaultProps = {
    title: 'Your Energy Details',
  };

  render() {
    const { isValidEntryForm } = this.props;

    return (
      <PageTemplate headerProps={this.props} footerProps={this.props}>
        <PageHeading title="Your Energy" />
        <ApplicationCheck isValidEntryForm={isValidEntryForm}>
          <EnergyProductSelectionFormContainer />
        </ApplicationCheck>
      </PageTemplate>
    );
  }
}

EnergySelectionPage.propTypes = {
  isValidEntryForm: PropTypes.bool.isRequired,
};

export default withTheme(EnergySelectionPage);
