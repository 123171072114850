import COLORS from 'constants/colors';
import media from 'constants/media.js';
import gstyles from 'constants/styles.js';

export default {
  root: {
    alignItems: 'center',
  },

  content: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row',
    [media.TABLET]: {
      flexDirection: 'column',
    },
  },

  contentLeft: {
    flex: '1 0 0',
    padding: 20,
  },

  contentRight: {
    justifyItems: 'ceneter',
    textAlign: 'center',
    flex: '1 0 0',
    [media.TABLET]: {
      display: 'none',
    },
  },

  mainHeading: {
    ...gstyles.fonts.sectionTitle,
    textAlign: 'left',
    color: COLORS.secondary.darkGrey,
  },

  secondaryHeading: {
    color: COLORS.secondary.darkGrey,
  },

  link: {
    color: COLORS.primary.magenta,
    marginBottom: 300,
    cursor: 'pointer',
  },

  heroBackground: gstyles.heroBackground,
};
