import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.common.white,
  },
  image: {
    alignSelf: 'center',
    display: 'none',
    maxHeight: 320,
    transform: 'scaleX(-1)',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      height: 300,
    },
    [theme.breakpoints.up('md')]: {
      height: 400,
      marginRight: theme.spacing(20),
    },
  },
});

export default makeStyles(style);
