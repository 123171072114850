import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import LoadingPage from 'components/pages/Loading';

const SignInIAM = ({ location, salesAuthIAMRequest }) => {
  useLayoutEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    salesAuthIAMRequest(token);
  }, []);

  return <LoadingPage title="Signing you in" />;
};

SignInIAM.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  salesAuthIAMRequest: PropTypes.func.isRequired,
};

export default withRouter(SignInIAM);
