import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import LoadingPage from 'components/pages/Loading';

const SignInTTJWT = ({ salesAuthTTJWTRequest }) => {
  useLayoutEffect(() => {
    let token = document.cookie
      .split(';')
      .find((c) => c.trim().startsWith('token='))
      .replace('token=', '');
    salesAuthTTJWTRequest(token);
  }, []);

  return <LoadingPage title="Signing you in" />;
};

SignInTTJWT.propTypes = {
  salesAuthTTJWTRequest: PropTypes.func.isRequired,
};

export default withRouter(SignInTTJWT);
