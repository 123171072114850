import React from 'react';

import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'components/modules/Shared/PageHeading';
import BoilerCoverContainer from 'redux/modules/BoilerCover/container';
import CollectionForm from 'redux/modules/HomeAudit/CollectionForm/container';
import { SERVICES } from 'redux/modules/HomeAudit/constants';

const BoilerCoverPage = (props) => {
  return (
    <PageTemplate
      helmetTitle={`Utility Warehouse - Join the club - Boiler & Home Cover`}
      headerProps={props}
      footerProps={props}
    >
      <PageHeading title="Boiler & Home Cover" />
      <ApplicationCheck>
        <CollectionForm
          service={SERVICES.INSURANCE}
          tooltip="Please don't include the cost of any other insurances aside from boiler and home cover"
        />
        <BoilerCoverContainer />
      </ApplicationCheck>
    </PageTemplate>
  );
};

export default BoilerCoverPage;
