import React from 'react';

import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'components/modules/Shared/PageHeading';
import BillIncomeProtector from 'redux/modules/BillIncomeProtector/container';

const BillIncomeProtectorPage = (props) => {
  return (
    <PageTemplate
      helmetTitle={`Utility Warehouse - Join the club - Bill & Income Protection`}
      headerProps={props}
      footerProps={props}
    >
      <PageHeading title="Bill Protector & Income Protector" />
      <ApplicationCheck>
        <BillIncomeProtector />
      </ApplicationCheck>
    </PageTemplate>
  );
};

export default BillIncomeProtectorPage;
