import React from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';

import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'components/modules/Shared/PageHeading';
import Cashback from 'redux/modules/Cashback/container';

const AddOns = (props) => {
  const { path } = useRouteMatch();
  // TODO: add path-based progress matching for eventual progress meter:
  //const { pathname: fullPath } = useLocation();
  //const routeMatch = new RegExp(`${path}(/[a-zA-Z0-9-]+)`).exec(fullPath);
  //const [, subPath] = routeMatch || [];
  //const paths = ['/boiler', '/billincomeprotector'];
  return (
    <PageTemplate
      helmetTitle={`Utility Warehouse - Join the club - Your add ons`}
      headerProps={props}
      footerProps={{ ...props, cashBack: true }}
    >
      <PageHeading title="Your add-ons" theme="darkAlternate" />
      <ApplicationCheck>
        <Switch>
          <Route path={`${path}/cashback`} component={Cashback} />
          <Route path={path}>
            <Redirect to={{ pathname: `${path}/cashback` }} />
          </Route>
        </Switch>
      </ApplicationCheck>
    </PageTemplate>
  );
};

export default AddOns;
