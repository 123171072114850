import React, { useEffect } from 'react';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import useEvents from 'app/lib/analytics/AnalyticsProvider/useEvents';
import { EVENTS } from 'app/lib/analytics/constants';
import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'components/modules/Shared/PageHeading';
import PaymentFormContainer from 'redux/modules/PaymentForm/container';

const PaymentPage = (props) => {
  const trackOnce = useTrackOnce();
  const paymentRequestState = useEvents(EVENTS.PAYMENT_REQUESTED);

  useEffect(() => {
    trackOnce(EVENTS.PAYMENT_REQUESTED, paymentRequestState);
  }, []);

  return (
    <PageTemplate
      helmetTitle="Your Payment"
      headerProps={props}
      footerProps={props}
    >
      <PageHeading title="Your Payment" />
      <PaymentFormContainer />
    </PageTemplate>
  );
};
export default PaymentPage;
