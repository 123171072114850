import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleRoot } from 'radium';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { TrackingProvider } from '@utilitywarehouse/partner-tracking-react';
import muiTheme from 'constants/muiTheme';
{
  /* TODO:temporary solution
                    https://linear.app/utilitywarehouse/issue/AQ-3400/handle-unsupported-browsers-on-join */
}
// import { detectBrowserSupport } from 'redux/utils/detectBrowserSupport';
import StartAgainDialog from 'modules/Shared/StartAgainDialog';
import LoadingPage from 'components/pages/Loading';
import ErrorBoundary from 'modules/Error/ErrorBoundary';
import { ApolloProvider } from '@apollo/client';

import NavigatorOnlineError from 'components/modules/Error/NavigatorOnlineError';
import AppDialog from 'components/modules/Shared/GlobalDialog';
import RemoteCursors from 'redux/modules/RemoteSession/v2/RemoteCursors';
import RemoteSessionOverlay from 'redux/modules/RemoteSession/v2/RemoteSessionOverlay/container';
import NotificationOverlay from 'redux/modules/Notifications/container';

import { STORE_NAME as APP_STORE_NAME } from 'redux/modules/App/constants';
import { STORE_NAME as DIALOG_STORE_NAME } from 'redux/modules/GlobalDialog/constants';
import {
  getIsOptlyReady,
  getIsSnowplowReady,
} from 'redux/modules/Analytics/api';
import { connector } from 'redux/utils/connector';
import { App, GlobalDialog } from 'redux/modules';
import { getClient } from 'app/lib/analytics';
import OptimizelyProvider from 'app/lib/analytics/Optimizely/OptimizelyProvider';
import Routes from '../../routes';
import HelmetCommon from './helmet.common';
import OneTrust from 'components/modules/OneTrust';
import TrackPages from 'app/lib/analytics/TrackPages';
import apolloClient from 'app/config/apolloClient';
class Root extends Component {
  // componentDidMount() {
  //   const isBrowserSupported = detectBrowserSupport();
  //   this.props.setBrowserDialog(!isBrowserSupported);
  // }
  render() {
    const {
      GlobalDialog,
      closeGlobalDialog,
      showStartAgainDialog,
      startAgain,
      setStartAgainDialog,
      optlyReady = false,
      snowplowReady = false,
    } = this.props;
    const { client } = getClient();

    const depsReady = optlyReady && snowplowReady;

    return (
      <StyleRoot>
        <OneTrust />
        <HelmetCommon />
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          <ApolloProvider client={apolloClient}>
            <ErrorBoundary>
              <TrackingProvider client={client}>
                <OptimizelyProvider>
                  {depsReady && (
                    <>
                      <TrackPages />
                      <AppDialog
                        {...GlobalDialog}
                        handleClose={closeGlobalDialog}
                      />
                      <RemoteSessionOverlay />
                      <NotificationOverlay />
                      <NavigatorOnlineError />
                      {/* TODO:temporary solution
                    https://linear.app/utilitywarehouse/issue/AQ-3400/handle-unsupported-browsers-on-join */}
                      {/* <BrowserSupportDialog
                      open={showBrowserDialog}
                      onClose={() => setBrowserDialog(false)}
                    /> */}
                      <StartAgainDialog
                        open={showStartAgainDialog}
                        onStartAgain={startAgain}
                        onClose={() => setStartAgainDialog(false)}
                      />
                      <Routes />
                      <RemoteCursors />
                    </>
                  )}
                  {!depsReady && <LoadingPage title="Loading" />}
                </OptimizelyProvider>
              </TrackingProvider>
            </ErrorBoundary>
          </ApolloProvider>
        </MuiThemeProvider>
      </StyleRoot>
    );
  }
}

Root.propTypes = {
  GlobalDialog: PropTypes.object,
  setBrowserDialog: PropTypes.func.isRequired,
  startAgain: PropTypes.func.isRequired,
  setStartAgainDialog: PropTypes.func.isRequired,
  showStartAgainDialog: PropTypes.bool.isRequired,
  showBrowserDialog: PropTypes.bool.isRequired,
  closeGlobalDialog: PropTypes.func.isRequired,
  optlyReady: PropTypes.bool,
  snowplowReady: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  ...state.get(APP_STORE_NAME).toJS(),
  ...state.get(DIALOG_STORE_NAME).toJS(),
  optlyReady: getIsOptlyReady(state),
  snowplowReady: getIsSnowplowReady(state),
});

export default connector(APP_STORE_NAME, mapStateToProps, {
  ...App,
  ...GlobalDialog,
})(Root);
