import React from 'react';

import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'components/modules/Shared/PageHeading';
import Bundles from 'redux/modules/Bundles/container';

const BundlePage = () => {
  return (
    <PageTemplate helmetTitle="Utility Warehouse - Join the club - Choose your bundle">
      <PageHeading title="Build your bundle" />
      <ApplicationCheck withoutServices>
        <Bundles />
      </ApplicationCheck>
    </PageTemplate>
  );
};

export default BundlePage;
