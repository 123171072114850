import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import PageTemplate from 'modules/layout/PageTemplate';
import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import { ENVIRONMENT } from 'config';
import { TITLE } from './copy';
import ThankYou from 'components/modules/ThankYouPage';

export class ThankYouPage extends Component {
  UNSAFE_componentWillUnmount() {
    this.handleLogout();
  }

  handleLogout(url) {
    const { history, logoutUserApplication } = this.props;
    logoutUserApplication();

    if (ENVIRONMENT === 'development') {
      history.push('/');
    } else {
      window.location.href = url;
    }
  }

  render() {
    const { accountNumber } = this.props;

    return (
      <PageTemplate helmetTitle={TITLE}>
        <ApplicationCheck>
          <ThankYou
            accountNumber={accountNumber}
            onLogout={(url) => this.handleLogout(url)}
          />
        </ApplicationCheck>
      </PageTemplate>
    );
  }
}

ThankYouPage.propTypes = {
  accountNumber: PropTypes.string,
  history: PropTypes.object.isRequired,
  logoutUserApplication: PropTypes.func.isRequired,
};

export default withRouter(ThankYouPage);
