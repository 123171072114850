import React from 'react';
import Helmet from 'react-helmet';

class HelmetImports extends React.Component {
  render() {
    return (
      <>
        <Helmet
          link={[
            {
              rel: 'stylesheet',
              type: 'text/css',
              href:
                'https://fonts.googleapis.com/css?family=Roboto:400,300,500',
            },
          ]}
        />

        <Helmet
          link={[
            {
              rel: 'icon',
              type: 'image/png',
              sizes: '16x16',
              href: 'images/favicon.ico',
            },
          ]}
        />
      </>
    );
  }
}

export default HelmetImports;
