import React from 'react';
import { makeStyles } from '@material-ui/core';
import ApplicationPresentationContainer from 'redux/modules/ApplicationPresentation/container';

const useStyles = makeStyles(() => ({
  container: {
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
  },
}));

const ApplicationPresentationPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ApplicationPresentationContainer />
    </div>
  );
};

export default ApplicationPresentationPage;
