// pages/Content/page.js
import React from 'react';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router';
import Radium from 'radium';
import PropTypes from 'prop-types';
import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'components/modules/Shared/PageHeading';
import SectionCentered from 'modules/layout/SectionCentered';
import Loading from 'modules/Shared/LoadingIndicator';

import Markdown from '../../modules/Markdown';

import styles from './styles';

class ContentPage extends React.Component {
  componentDidMount() {
    const { match, fetchPageRequest } = this.props;
    fetchPageRequest(match.params.slug);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { type, slug } = nextProps.match.params || {};
    const { match, fetchPageRequest } = this.props;

    if (type && slug) {
      if (match.params.slug !== slug || match.params.type !== type) {
        fetchPageRequest(slug);
      }
    }
  }

  render() {
    const { content, title, loading } =
      this.props[this.props.match.params.slug] || {};

    return (
      <PageTemplate
        helmetTitle="Utility Warehouse - Join the club"
        headerProps={this.props}
        footerProps={this.props}
        baseStyles={styles.base}
      >
        {title && <PageHeading title={title} />}
        <SectionCentered>
          {loading && <Loading loading />}
          {content && <Markdown>{content}</Markdown>}
        </SectionCentered>
      </PageTemplate>
    );
  }
}

ContentPage.propTypes = {
  fetchPageRequest: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default compose(withRouter, Radium)(ContentPage);
