import React from 'react';

import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'components/modules/Shared/PageHeading';
import { YourDetailsContainer } from 'app/redux/modules/YourDetails/containers/YourDetailsContainer';

const YourDetailsPage = () => {
  return (
    <PageTemplate helmetTitle="Your Details">
      <PageHeading title="Your details" />
      <ApplicationCheck>
        <YourDetailsContainer />
      </ApplicationCheck>
    </PageTemplate>
  );
};

export default YourDetailsPage;
