import React from 'react';
import PropTypes from 'prop-types';
import PageHeading from 'modules/Shared/PageHeading';
import PageTemplate from 'modules/layout/PageTemplate';
import { Loading } from '@utilitywarehouse/partner-ui-mui-components';
import useStyles from './styles';

const LoadingPage = ({ title = 'Loading' }) => {
  const classes = useStyles();

  return (
    <PageTemplate helmetTitle={title}>
      <PageHeading />
      <div className={classes.loading}>
        <Loading loadingText={title} />
      </div>
    </PageTemplate>
  );
};

LoadingPage.propTypes = {
  title: PropTypes.string,
};

export default LoadingPage;
