import React from 'react';
import PageHeading from 'modules/Shared/PageHeading';
import PageTemplate from 'modules/layout/PageTemplate';
import CustomerForm from 'redux/modules/RemoteSession/v2/CustomerForm/container';
import SectionCentered from 'modules/layout/SectionCentered';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import * as consts from './consts';

export const RemoteJoin = ({ classes }) => {
  return (
    <PageTemplate
      helmetTitle="Join Remote Appointment"
      headerProps={{}}
      footerProps={{}}
    >
      <PageHeading autoScroll={false}>
        <Typography className={classes.title} variant="h1">
          {consts.TITLE}
        </Typography>
      </PageHeading>
      <SectionCentered>
        <CustomerForm />
      </SectionCentered>
    </PageTemplate>
  );
};

export default withStyles(styles)(RemoteJoin);
