import React from 'react';
import PropTypes from 'prop-types';
import PageTemplate from 'modules/layout/PageTemplate';
import { PreselectLoading } from 'modules/PreselectLoading';

const PreselectLoadingPage = ({ title = 'Loading' }) => {
  return (
    <PageTemplate helmetTitle={title} hideFooter>
      <PreselectLoading />
    </PageTemplate>
  );
};

PreselectLoadingPage.propTypes = {
  title: PropTypes.string,
};

export default PreselectLoadingPage;
