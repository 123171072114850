import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Redirect } from 'react-router-dom';

import { Alert, AlertTitle } from '@material-ui/lab';
import { Loading } from '@utilitywarehouse/partner-ui-mui-components';
import { Typography, Box } from '@material-ui/core';

import PageHeading from 'modules/Shared/PageHeading';
import PageTemplate from 'modules/layout/PageTemplate';
import SectionCentered from 'modules/layout/SectionCentered';

import { CUSTOMER_RELATIONSHIP_NEW } from 'redux/modules/PartnerLogin/constants';

export const RestoreLandingPage = ({ setToken, updateField }) => {
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  useEffect(() => {
    if (token) {
      // NOTE: preselect type so the appointment could start however it will be
      // replaced immediately after restore so it doesn't matter which type is
      // set - it just needs to be present.
      updateField('customerRelationship', CUSTOMER_RELATIONSHIP_NEW);
      setToken(token);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageTemplate helmetTitle="Restore Appointment">
      <PageHeading autoScroll={false}>
        <Typography variant="h1"></Typography>
      </PageHeading>
      <SectionCentered>
        <Typography variant="h2" color="textPrimary">
          Restoring your session
        </Typography>
        {token ? (
          <Box p={10}>
            <Loading loadingText="Loading..." />
            <Redirect to="/" />
          </Box>
        ) : (
          <Box m={3}>
            <Alert severity="error" variant="filled">
              <AlertTitle>Invalid restore link.</AlertTitle>
            </Alert>
          </Box>
        )}
      </SectionCentered>
    </PageTemplate>
  );
};

RestoreLandingPage.propTypes = {
  setToken: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
};

export default RestoreLandingPage;
