import {
  getBreakpoints,
  getSpacing,
  getCommonPaletteColor,
} from 'app/lib/theme';

export default (theme) => {
  const breakpoints = getBreakpoints(theme);
  const spacing = getSpacing(theme);
  const white = getCommonPaletteColor('white')(theme);

  return {
    container: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      color: white,
    },
    image: {
      alignSelf: 'center',
      display: 'none',
      maxHeight: 320,
      transform: 'scaleX(-1)',
      [breakpoints.up('sm')]: {
        display: 'block',
        height: 300,
      },
      [breakpoints.up('md')]: {
        height: 400,
        marginRight: spacing(20),
      },
    },
  };
};
