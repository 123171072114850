import { createBrowserHistory } from 'history';
import { CONFIG_BASE_URL } from 'config';
import * as routeActions from 'redux/modules/Routes/actions';

// configure, create, and export the project's history instance
const history = createBrowserHistory({
  basename: CONFIG_BASE_URL,
});

history.listen(({ pathname }) => {
  // since there's no connected-react-router implementation,
  // this acts as a stop-tap until someone refactors away all
  // the direct calls to the 'history' object and uses the router
  // itself to handle route changes
  window.store.dispatch(routeActions.routeChange(pathname));
});

export default history;
