import React from 'react';
import Typography from '@material-ui/core/Typography';
import illustrations from '@utilitywarehouse/partner-ui-assets/lib/js/images/illustrations';

import PageHeading from 'modules/Shared/PageHeading';
import PageTemplate from 'modules/layout/PageTemplate';
import RemoteSupport from 'redux/modules/RemoteSession/v2/PartnerForm/RemoteSupport/container';

import useStyles from './styles';

const RemoteWithCustomer = () => {
  const classes = useStyles();
  return (
    <PageTemplate helmetTitle="Join Remote Appointment">
      <PageHeading autoScroll={false}>
        <div className={classes.container}>
          <Typography className={classes.title} variant="h1">
            Your remote appointment
          </Typography>
          <img
            className={classes.image}
            alt="woman image"
            src={illustrations.i6.outlined}
          />
        </div>
      </PageHeading>
      <RemoteSupport />
    </PageTemplate>
  );
};

export default RemoteWithCustomer;
