import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '@material-ui/core';
import AddressPicker from 'modules/Shared/AddressPickerNew';
import RadioButtonGroup from 'modules/Shared/MultiSelection/RadioButtonGroup';
import useStyles from './styles';

const LandingPageAddressPicker = ({
  isPropertyOwner,
  setIsPropertyOwner,
  loading,
  onConfirm,
  addressPicker,
  getAddressList,
  onSelectAddress,
  setManualEntry,
  setManualEntryField,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.addressPickerContainer}>
      <AddressPicker
        postcode={addressPicker?.postcode}
        addresses={addressPicker?.addressList}
        loading={loading}
        fetchingAddressList={addressPicker?.loading}
        selectedAddress={addressPicker.selectedAddress}
        onChangePostcode={(postcode) => getAddressList(postcode)}
        onSelectAddress={onSelectAddress}
        manualEntry={addressPicker.manualEntry}
        onSelectManualEntry={setManualEntry}
        onChangeAddressField={setManualEntryField}
        addressFields={addressPicker.addressFields}
        onSubmit={onConfirm}
        additionalFieldsValid={typeof isPropertyOwner === 'boolean'}
        classes={{ root: classes.addressPicker }}
        PostcodeInputProps={{
          dataTestId: 'LandingPageAddressPostcode',
        }}
        AddressResultsProps={{
          dataTestId: 'LandingPageAddressSelect',
        }}
      >
        <FormLabel className={classes.propertySelectLabel}>
          Do you own your home?
        </FormLabel>
        <RadioButtonGroup
          row
          name="propertyOwner"
          value={isPropertyOwner}
          radioGroupClasses={{
            container: classes.propertySelect,
            radioGroup: classes.propertySelectItems,
            radioButton: classes.propertySelectItem,
          }}
          onChange={(event) => {
            const parseValue = (value) => {
              if (value === 'true') return true;
              if (value === 'false') return false;
              return value;
            };

            const value = parseValue(event.target.value);
            setIsPropertyOwner(value);
          }}
          data-test-id="landingPropertyOwner"
          options={[
            {
              label: 'Yes',
              value: true,
              testId: 'LandingPageOwnPropertyYes',
            },
            {
              label: 'No',
              value: false,
              testId: 'LandingPageOwnPropertyNo',
            },
          ]}
        />
      </AddressPicker>
    </div>
  );
};

LandingPageAddressPicker.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  setIsPropertyOwner: PropTypes.func.isRequired,
  getAddressList: PropTypes.func.isRequired,
  onSelectAddress: PropTypes.func.isRequired,
  setManualEntry: PropTypes.func.isRequired,
  setManualEntryField: PropTypes.func.isRequired,
  isPropertyOwner: PropTypes.bool,
  loading: PropTypes.bool,
  addressPicker: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    addressList: PropTypes.arrayOf(PropTypes.object),
    selectedAddress: PropTypes.string,
    postcode: PropTypes.string,
    manualEntry: PropTypes.bool,
    addressFields: PropTypes.objectOf(PropTypes.string),
  }),
};

export default LandingPageAddressPicker;
