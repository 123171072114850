import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PageTemplate from 'modules/layout/PageTemplate';
import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import ThankYou from 'redux/modules/ThankYouPageNew/container';
import useEvents from 'app/lib/analytics/AnalyticsProvider/useEvents';
import { EVENTS } from 'app/lib/analytics/constants';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import useOptlyEvent from 'app/lib/analytics/Optimizely/useOptlyEvent';
import { events as optlyEvents } from 'app/lib/analytics/Optimizely/features';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { APP_DURATION_MAX } from 'config';

const ThankYouPage = () => {
  const appDuration = useSelector(OrderSummaryApi.getApplicationDuration);
  const trackOnce = useTrackOnce();
  const coreServicesState = useEvents(EVENTS.APPLICATION_COMPLETED);
  const optlyEvent = useOptlyEvent();
  const hasBroadband = useSelector(OrderSummaryApi.getHasBroadband);
  const insuranceServices = useSelector(OrderSummaryApi.getInsuranceServices);

  useEffect(() => {
    trackOnce(EVENTS.APPLICATION_COMPLETED, coreServicesState);

    const eventsToTrigger = [];

    if (hasBroadband) {
      eventsToTrigger.push(
        optlyEvents.APPLICATION_COMPLETED_WITH_BROADBAND_SELECTED
      );
    }

    if (insuranceServices.length > 0) {
      eventsToTrigger.push(
        optlyEvents.APPLICATION_COMPLETED_WITH_INSURANCE_SELECTED
      );
    }

    if (insuranceServices.includes('boilerInsurance')) {
      eventsToTrigger.push(optlyEvents.BOILER_HOME_COVER_SUBMITTED);
    }

    if (insuranceServices.includes('incomeProtector')) {
      eventsToTrigger.push(optlyEvents.INCOME_PROTECTOR_SUBMITTED);
    }

    if (insuranceServices.includes('billProtector')) {
      eventsToTrigger.push(optlyEvents.BILL_PROTECTOR_SUBMITTED);
    }

    eventsToTrigger.forEach((event) => optlyEvent(event));

    optlyEvent(optlyEvents.APPLICATION_COMPLETED, {
      value:
        appDuration <= APP_DURATION_MAX && appDuration > 0
          ? appDuration
          : undefined,
    });
  }, [
    appDuration,
    optlyEvent,
    coreServicesState,
    trackOnce,
    hasBroadband,
    insuranceServices,
  ]);

  return (
    <PageTemplate helmetTitle="Thank you for your order">
      <ApplicationCheck>
        <ThankYou />
      </ApplicationCheck>
    </PageTemplate>
  );
};

export default ThankYouPage;
