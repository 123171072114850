import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  topSection: {
    paddingBottom: theme.spacing(5),
  },
  section: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  sectionPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  sectionGray: {
    backgroundColor: theme.palette.common.gray,
  },
  sectionWhite: {
    backgroundColor: theme.palette.common.white,
  },
  sectionCashback: {
    position: 'relative',
    display: 'grid',
    gridTemplateRows: '1fr 250px',
    [theme.breakpoints.up('sm')]: {
      gridTemplateRows: '1fr',
      gridTemplateColumns: '1fr 1fr',
      minHeight: 384,
    },
  },
  sectionHeading: {
    marginBottom: theme.spacing(6),
  },
  sectionTitle: {
    // TODO: update partner-ui typography
    fontSize: 28,
    fontWeight: theme.typography.fontWeightBold,

    [theme.breakpoints.up('sm')]: {
      fontSize: 32,
    },
  },
  subSectionTitle: {
    fontSize: 22,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
  },
  headerTitle: {
    maxWidth: '32ch',
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  servicesWrapper: {
    display: 'grid',
    gap: `${theme.spacing(4)}px`,

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  serviceItem: {
    '& header': {
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  serviceIconLarge: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  serviceIconSmall: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  serviceIconElectricity: {
    color: theme.palette.services.electricity,
  },
  serviceIconBroadband: {
    color: theme.palette.services.broadband,
  },
  serviceIconMobile: {
    color: theme.palette.services.mobile,
  },
  serviceIconInsurance: {
    color: theme.palette.services.insurance,
  },
  servicesLegalDropdown: {
    marginTop: theme.spacing(3),
  },
  featuresWrapper: {
    display: 'grid',
    gap: `${theme.spacing(4)}px`,

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    rowGap: `${theme.spacing(2)}px`,
    textAlign: 'center',
  },
  cashbackSectionTitle: {
    marginBottom: theme.spacing(2),
  },
  cashbackCopy: {
    [theme.breakpoints.up('sm')]: {},
  },
  cashbackList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  cashbackListItem: {
    display: 'inline-flex',
    gap: `${theme.spacing(1)}px`,
    flexDirection: 'row',
    alignItems: 'flex-start',

    '& > svg': {
      color: theme.palette.success.main,
      marginRight: theme.spacing(1),
    },

    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  cashbackLegalDropdown: {
    marginTop: theme.spacing(4),
  },
  cashbackImages: {
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      bottom: theme.spacing(-5),
    },
    '& img': {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',

      [theme.breakpoints.up('md')]: {
        transform: 'none',
        left: 'auto',
      },
    },
  },
  propertySelect: {
    color: theme.palette.text.primary,
    maxWidth: 430,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  propertySelectLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    color: 'inherit',
  },
  propertySelectItems: {
    display: 'flex',
  },
  propertySelectItem: {
    flexGrow: 1,
  },
  addressPickerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(3)}px`,
  },
  addressPicker: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 320,
    },
  },
  addressPickerLoading: {
    marginTop: theme.spacing(1),
  },
  base: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  subTitle: {
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  hero: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  wrapper: {
    maxWidth: '504px',
    width: '100%',
  },
  img: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },

    '& img': {
      width: '100%',
      maxWidth: 439,
      transform: 'scale(1.2)',
    },
  },
});

export default makeStyles(style);
