import React from 'react';

import PageTemplate from 'modules/layout/PageTemplate';
import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import PageHeading from 'components/modules/Shared/PageHeading';
import OrderSummaryContainer from 'app/redux/modules/OrderSummary/containers/summaryPageContainer';

const OrderSummary = (props) => {
  const title = 'Your quote';

  return (
    <PageTemplate helmetTitle={title} headerProps={props} footerProps={props}>
      <PageHeading title={title} />
      <ApplicationCheck>
        <OrderSummaryContainer />
      </ApplicationCheck>
    </PageTemplate>
  );
};

export default OrderSummary;
