import React from 'react';

import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import PageTemplate from 'modules/layout/PageTemplate';
import MobileContainer from 'redux/modules/Mobile/containers';
import NumberOptionDialogContainer from 'app/redux/modules/Mobile/containers/NumberOptionDialogContainer';
import SpendCapDialogContainer from 'app/redux/modules/Mobile/containers/SpendCapDialogContainer';
import PageHeading from 'modules/Shared/PageHeading';

const MobilePage = () => (
  <PageTemplate helmetTitle="Your Mobile">
    <PageHeading title="Your Mobile" />
    <ApplicationCheck>
      <NumberOptionDialogContainer />
      <SpendCapDialogContainer />
      <MobileContainer />
    </ApplicationCheck>
  </PageTemplate>
);

export default MobilePage;
