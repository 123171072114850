import { makeStyles } from '@material-ui/core/styles';
import {
  getBreakpoints,
  getCommonPaletteColor,
  getSpacing,
} from 'app/lib/theme';

export default makeStyles((theme) => {
  const spacing = getSpacing(theme);
  const breakpoints = getBreakpoints(theme);
  const white = getCommonPaletteColor('white')(theme);

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      color: white,
    },
    subtitle: {
      color: white,
      margin: spacing(2, 0, 3, 0),
    },
    link: {
      color: white,
      cursor: 'pointer',
    },
    image: {
      alignSelf: 'center',
      display: 'none',
      maxHeight: 320,
      transform: 'scaleX(-1)',
      [breakpoints.up('sm')]: {
        display: 'block',
        height: 300,
      },
      [breakpoints.up('md')]: {
        height: 400,
        marginRight: spacing(20),
      },
    },
    hero: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
});
