import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PageHeading from 'modules/Shared/PageHeading';
import PageTemplate from 'modules/layout/PageTemplate';
import SectionCentered from 'modules/layout/SectionCentered';
import { Loading } from '@utilitywarehouse/partner-ui-mui-components';

export const RestoreSession = ({ loading, error, restoreState }) => {
  useEffect(() => {
    restoreState();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageTemplate helmetTitle="Restore Appointment">
      <PageHeading autoScroll={false}>
        <Typography variant="h1"></Typography>
      </PageHeading>
      <SectionCentered>
        <Typography variant="h2" color="textPrimary">
          Restoring your session
        </Typography>
        {loading && (
          <Box p={10}>
            <Loading loadingText="Fetching appointment data..." />
          </Box>
        )}

        {error && (
          <Box m={3}>
            <Alert severity="error" variant="filled">
              <AlertTitle>{error}</AlertTitle>
            </Alert>
          </Box>
        )}
      </SectionCentered>
    </PageTemplate>
  );
};

RestoreSession.propTypes = {
  restoreState: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default RestoreSession;
