import React from 'react';

import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'components/modules/Shared/PageHeading';

import EnergyDetails from 'components/modules/Energy/Details';

const EnergyDetailsPage = (props) => {
  return (
    <PageTemplate helmetTitle="Your Energy">
      <PageHeading title="Your Quotation" />
      <ApplicationCheck>
        <EnergyDetails {...props} />
      </ApplicationCheck>
    </PageTemplate>
  );
};

export default EnergyDetailsPage;
